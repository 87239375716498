import React, { useEffect } from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  const handleLocationClick = (event: any) => {
    event.preventDefault(); // Prevent default anchor tag behavior
    navigate("/#section"); // Navigate programmatically to '/#section'
  };
  const handleLocationClick2 = (event: any) => {
    event.preventDefault(); // Prevent default anchor tag behavior
    navigate("/requirements/#contact"); // Navigate programmatically to '/#section'
  };

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center bg-[#0404040A] lg:space-x-4 relative ">
      {NAVIGATION_DEMO.reduce<React.ReactNode[]>((acc, item, index) => {
        acc.push(<NavigationItem key={item.id} menuItem={item} />);
        
       
        return acc;
      }, [])}
    </ul>
  );
}

export default Navigation;
