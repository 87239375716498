import rightImg from "images/image.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero2 from "./SectionHero2";
import AboutUsSection from "./AboutUsSection";
import SectionInfoBanner from "./SectionInfoBanner";
import WhyChooseUs from "components/WhyChooseUS/WhyChooseUs";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>
      <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        About Us
      </h2>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 ">
        <SectionHero
          rightImg={rightImg}
          heading="Welcome to EH Rentals"
          btnText=""
          subHeading={globalJson?.about_us_para}
        />
      </div>
      <WhyChooseUs/>
      {/* <SectionFounder /> */}
      <div className="relative py-16">
        {/* <BackgroundSection /> */}
        <SectionClientSay uniqueClassName="PageAbout_" />
      </div>
    </div>
  );
};

export default PageAbout;
