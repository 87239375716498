import clientSay1 from "images/client1.jpg";
import clientSay4 from "images/client2.jpg";
import clientSay5 from "images/client3.jpg";

export const globalJson = {
  prod_company_id: 136,
  dev_company_id: 136,

  // apiUtil auth header key
  authToken: "Bearer 168|3oLFn8OniSxk1UBTO3eQeGXMuViDTsZqAwhalwWm",
  companyId: "7514a219-48ce-439e-a2c5-6f38980cee1a",
  stripePublishableKey:
    "pk_live_51LdnoABqYa4ICTHoC6CjEgImHYOvUXXPs8psg8jZqXPxRwBt99nQEWtFFJj2n3ublkCJa0S8pYzDtvStF2JxUPzU00uWCskvlb",
  mapKey: "AIzaSyDKEntg7JydrxI7zr2tQL5OYcS5M-er-bc",
  residenceStateCode: "AZ",

  //email confirmation admin token
  adminEmailToken:
    "XR85jFku2zMMqvsYmAdkrk1lm6pozj7eFXetRHLNSSsLxRLnAHgY3dPaVsE27OK8cIbnhFQmncWxVjS1XLAhneFEFpwVVeaNE7hMOym2vrhzdKSmxn99V0fLvVJyZLPT",
  websiteLink: "https://EH Rentals-dev.1now.app",
  sender: "EH Rentals",
  ccEmail: "EH Rentals@gmail.com",

  // apiUtil sendNewsLetterEmail
  util_serviceId: "service_2xca7kj",
  util_templateId: "template_smckeo7",
  util_userId: "ATdsFr5wumje11YkV",
  util_privateKey: "TJyyV_e7nNOGPKbyyjpMB",
  util_contact_templateId: "template_9dsuz0w",

  // apiUtil getBonzahToken
  client_secret_dev: "",
  client_id_dev: "",
  client_secret_prod: "",
  client_id_prod: "",

  about_us_para:
    "An Atlanta-based rental company with a commitment to excellence in providing outstanding customer care and high quality, well-maintained vehicles.",

  clientSays_arr: [
    {
      id: 1,
      clientName: "John D.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "The service was excellent, and the car was in top-notch condition. I'll definitely rent from here again!",
      img: clientSay1,
    },
    {
      id: 2,
      clientName: "Samantha L.",
      clientAddress: "LondDodge Grand Caravan 2019",
      content:
        "The process was so smooth, and I appreciated the friendly staff. Highly recommend this company for car rentals!",
      img: clientSay4,
    },
    {
      id: 3,
      clientName: "Michael R.",
      clientAddress: "Dodge Grand Caravan 2019",
      content:
        "The car was clean, affordable, and reliable. I had a great experience and will use them again for my next trip!",
      img: clientSay5,
    },
  ],

  //social links
  facebook:
    "https://www.facebook.com/",
  instagram:
    "https://www.instagram.com/",

  //contact
  address: "3060 Mercer University Drive Suite 110 Atlanta GA 30341",
  addressLink:
    "https://www.google.com/maps/place/4830+W+Kennedy+Blvd,+Tampa,+FL+33609,+USA/data=!4m2!3m1!1s0x88c2c2e4b61fbe87:0x7fa31f86943d0895?sa=X&ved=1t:242&ictx=111",
  phone: "470-985-0805", //pending
  email: "ekonhekima@gmail.com", //pending
};

export const scriptHubSpot = document.createElement("script");
scriptHubSpot.src = "//js-na1.hs-scripts.com/46776911.js";
scriptHubSpot.async = true;
scriptHubSpot.defer = true;
scriptHubSpot.id = "hs-script-loader";
