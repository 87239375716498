"use client";

import { MapPinIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC } from "react";
import ClearDataButton from "./ClearDataButton";
import LatLng from "models/Latlng";
import { getNearbyLocations, searchLocations } from "api/locations/locations";
import toast from "react-hot-toast";
import { useData } from "data/data-provider";
import LocationDropoffModal from "./LocationDropoffModal";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  valueFromParent?: string;
}

const DropOffLocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
}) => {
  const {
    ourLocation,
    setDropoffLocation,
    searchedDropoffLocation,
    setSearchedDropoffLocation,
    dropOffLocation,
    ourCompany,
  } = useData();
  const [searchedLocations, setSearchedLocations] = useState<any>();
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [companyDataLoading, setCompanyDataLoading] = useState<boolean>(true);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);

  const [value, setValue] = useState("");
  const [showPopover, setShowPopover] = useState(autoFocus);

  const callSearchLocationsAPI = async (input: string) => {
    try {
      setCompanyDataLoading(true);
      const data = (await searchLocations(input)) as any;
      setSearchedLocations(data.predictions);
    } catch (error: any) {
      toast.error(error.message); // Or handle errors in a more sophisticated way
    } finally {
      setCompanyDataLoading(false);
    }
  };

  useEffect(() => {
    callSearchLocationsAPI(searchedDropoffLocation);
  }, [searchedDropoffLocation]);
  useEffect(() => {
    // searchLocations("NewYork");
    // searchLocations("queens");
    // getNearbyLocations("ChIJRxcAvRO7j4AR6hm6tys8yA8");
    // getLocationFromLatLong("31.5287308,74.3285889");
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (dropOffLocation === null) {
      setSearchedDropoffLocation(""); // Clear state
      if (inputRef.current) {
        inputRef.current.value = ""; // Clear the input field display
      }
    }
  }, [dropOffLocation, setSearchedDropoffLocation]);

  const handleSelectLocation = (item: any) => {
    setSearchedDropoffLocation("");
    setDropoffLocation(null);
    if (item?.radius > 0) {
      setSelectedLocation(item); // Store selected location
      setShowLocationModal(true); // Open modal if location has a radius
      setShowPopover(false);
    } else {
      setDropoffLocation(item);
      setShowPopover(false);
    }
  };
  async function checkLocationInRange(placeId: string): Promise<LatLng | null> {
    try {
      const placeDetailsResponse = (await getNearbyLocations(placeId)) as any;

      if (placeDetailsResponse.result) {
        // Extract the latlng from the result
        const lat = placeDetailsResponse.result.geometry.location.lat;
        const lng = placeDetailsResponse.result.geometry.location.lng;
        return {
          latitude: lat,
          longitude: lng,
        };
      } else {
        toast.error(`Place Details API error: ${placeDetailsResponse.status}`);
      }
    } catch (error) {
      toast.error("Error fetching location details:" + error);
    }

    return null;
  }

  function radians(degrees: number): number {
    return degrees * (Math.PI / 180);
  }

  function calculateDistance(start: LatLng, end: LatLng): number {
    const earthRadius = ourCompany?.distance_unit === "miles" ? 3958.8 : 6371; // Earth's radius in meters

    const lat1 = radians(start.latitude);
    const lon1 = radians(start.longitude);
    const lat2 = radians(end.latitude);
    const lon2 = radians(end.longitude);

    const dLat = lat2 - lat1;
    const dLon = lon2 - lon1;

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;

    return distance;
  }

  const handleSelectSearchedLocation = async (item: any) => {
    const position = (await checkLocationInRange(
      item.place_id
    )) as LatLng | null;

    let minDistanceDeliveryLocation: any = null;
    let minDistance = Infinity;
    let locationsNearUser = [];

    if (position) {
      for (const location of ourLocation ?? []) {
        if (location.radius !== null) {
          const startLatLng = {
            latitude: Number(location.latitude),
            longitude: Number(location.longitude),
          };

          const endLatLng = {
            latitude: Number(position.latitude),
            longitude: Number(position.longitude),
          };
          let distance = calculateDistance(startLatLng, endLatLng);
          if (ourCompany?.distance_unit === "miles") {
            distance /= 1.609344; // Convert km to miles if the unit is miles
          }
          if (distance <= location.radius) {
            if (minDistanceDeliveryLocation) {
              if (minDistance > distance) {
                minDistanceDeliveryLocation = location;
                minDistance = distance;
              }
            } else {
              minDistanceDeliveryLocation = location;
              minDistance = distance;
            }
          }
        }
      }
    }

    if (minDistanceDeliveryLocation) {
      locationsNearUser.push(minDistanceDeliveryLocation);
      setDropoffLocation(minDistanceDeliveryLocation);
      // if (sameDropOffLocation === true) {
      //   dropOffLocationExtras?.options.map((location) => {
      //     if (location.title === minDistanceDeliveryLocation?.name) {
      //       setSelectedDropOffLocationExtra(location);
      //     }
      //   });
      // }
      setSearchedDropoffLocation(item.description);
      setShowPopover(false);
    } else {
      toast.error("Sorry, not yet available! Search for a nearby location.");
    }
  };
  const renderRecentSearches = () => {
    return (
      <>
        <div className="mt-2">
          {ourLocation
            .filter(
              (item) =>
                item.active === true && // strict check for active items
                (item.location_type === "drop_off" ||
                  item.location_type === "both")
            )
            .sort((a, b) => a.price - b.price)

            .map((item) => (
              <span
                onClick={() => handleSelectLocation(item)}
                key={item.id}
                className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-primary-200 hover:text-white dark:hover:bg-neutral-700 cursor-pointer justify-between"
              >
                <div className="flex">
                  <span className="block text-neutral-400">
                    <MapPinIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                  </span>
                  <span className="text-left block font-medium  mx-2">
                    {item.name}
                  </span>
                </div>
                <span className=" block font-medium ">
                  {item.price === 0 ? "Free" : "$" + item.price + "/trip"}
                </span>
              </span>
            ))}
        </div>
      </>
    );
  };

  const handleClear = () => {
    setSearchedDropoffLocation("");
    setDropoffLocation(null);
    setShowPopover(false);
    if (inputRef.current) {
      inputRef.current.value = ""; // Reset the input field's display
    }
  };

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          <input
            className={`block w-full bg-transparent cursor-pointer border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-white dark:placeholder-neutral-200 text-white truncate`}
            placeholder={placeHolder}
            value={
              searchedDropoffLocation.length > 0
                ? searchedDropoffLocation
                : dropOffLocation?.address
            }
            autoFocus={showPopover}
            onChange={(e) => {
              setSearchedDropoffLocation(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span className="block mt-0.5 text-sm text-[#FFFFFF80] font-light ">
            <span className="line-clamp-1">{desc}</span>
          </span>
          {searchedDropoffLocation && showPopover && (
            <ClearDataButton onClick={handleClear} />
          )}
        </div>
      </div>

      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0  dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}

      {showPopover &&
        ((searchedDropoffLocation && searchedLocations?.length > 0) ||
          ourLocation.filter(
            (item) =>
              item.active === true &&
              (item.location_type === "drop_off" ||
                item.location_type === "both")
          ).length > 0) && (
          <div
            className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-[#ffffff] dark:bg-[#414141F2] border border-[#E5E7EB] top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto hide-scrollbar"
            style={{ zIndex: 9999 }}
          >
            {renderRecentSearches()}
          </div>
        )}
      <LocationDropoffModal
        isOpen={showLocationModal}
        selectedLocation={selectedLocation}
        onClose={() => setShowLocationModal(false)}
        ourCompany={ourCompany}
        setSearchedDropoffLocation={setSearchedDropoffLocation}
        setDropoffLocation={setDropoffLocation}
        ourLocation={ourLocation}
      />
    </div>
  );
};

export default DropOffLocationInput;
