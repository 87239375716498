import React, { useState } from "react";
import Minus from "images/Minus.svg";
import Plus from "images/Plus.svg";
import NcImage from "shared/NcImage/NcImage";

interface SectionProps {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onToggle: () => void;
}

const Section: React.FC<SectionProps> = ({
  title,
  children,
  isOpen,
  onToggle,
}) => {
  return (
    <div className="border my-4 bg-white rounded-2xl px-6">
      <button
        onClick={onToggle}
        className="flex justify-between w-full px-4 py-6 text-left items-center"
      >
        <span className="text-2xl font-semibold leading-7 text-left text-[#242331]">
          {title}
        </span>
        <span>
          {isOpen ? (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="30"
                cy="29.5"
                r="29.5"
                transform="rotate(90 30 29.5)"
                fill="#013D79"
              />
              <path
                d="M30.1429 34.9609C30.2768 34.9609 30.4275 34.894 30.5279 34.7935L38.3292 26.9922C38.4297 26.8917 38.4967 26.7411 38.4967 26.6071C38.4967 26.4732 38.4297 26.3225 38.3292 26.2221L37.4922 25.385C37.3917 25.2846 37.2411 25.2176 37.1071 25.2176C36.9732 25.2176 36.8225 25.2846 36.7221 25.385L30.1429 31.9643L23.5636 25.385C23.4632 25.2846 23.3125 25.2176 23.1786 25.2176C23.0279 25.2176 22.894 25.2846 22.7935 25.385L21.9565 26.2221C21.856 26.3225 21.7891 26.4732 21.7891 26.6071C21.7891 26.7411 21.856 26.8917 21.9565 26.9922L29.7578 34.7935C29.8583 34.894 30.0089 34.9609 30.1429 34.9609Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="60"
              height="59"
              viewBox="0 0 60 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="30" cy="29.5" r="29.5" fill="#013D79" />
              <path
                d="M35.4609 29.3571C35.4609 29.2232 35.394 29.0725 35.2935 28.9721L27.4922 21.1708C27.3917 21.0703 27.2411 21.0033 27.1071 21.0033C26.9732 21.0033 26.8225 21.0703 26.7221 21.1708L25.885 22.0078C25.7846 22.1083 25.7176 22.2589 25.7176 22.3929C25.7176 22.5268 25.7846 22.6775 25.885 22.7779L32.4643 29.3571L25.885 35.9364C25.7846 36.0368 25.7176 36.1875 25.7176 36.3214C25.7176 36.4721 25.7846 36.606 25.885 36.7065L26.7221 37.5435C26.8225 37.644 26.9732 37.7109 27.1071 37.7109C27.2411 37.7109 27.3917 37.644 27.4922 37.5435L35.2935 29.7422C35.394 29.6417 35.4609 29.4911 35.4609 29.3571Z"
                fill="white"
              />
            </svg>
          )}
        </span>
      </button>
      {isOpen && <div className="px-4 py-2">{children}</div>}
    </div>
  );
};

const RentalPolicies: React.FC = () => {
  const [openSectionIndex, setOpenSectionIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setOpenSectionIndex(openSectionIndex === index ? null : index);
  };
  return (
    <>
      <div className="flex justify-center mb-16">
        <span className="text-4xl text-white font-bold leading-10 text-center tracking-tight">
          Frequently Asked Questions FAQ
        </span>
      </div>
      <div className="max-w-3xl mx-auto mt-4">
        <Section
          title="What types of vehicles do you have?"
          isOpen={openSectionIndex === 1}
          onToggle={() => handleToggle(1)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            We specialize in providing fuel-efficient economy class vehicles,
            2014 and newer.
          </span>
        </Section>
        <Section
          title="What do I need to be able to rent?"
          isOpen={openSectionIndex === 2}
          onToggle={() => handleToggle(2)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            A valid driver’s license and a major credit card.
          </span>
        </Section>
        <Section
          title="I have insurance, can I use that instead of the one provided"
          isOpen={openSectionIndex === 3}
          onToggle={() => handleToggle(3)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            It depends on your reason for renting the car. Some insurance
            companies will not cover a rental car that is used for gig work
            (Uber, Lyft, DoorDash, etc.) Call your insurance company to ask if
            your policy extends to rental cars. If you’re renting the car for
            any other reason, you are likely able to extend it to rental cars,
            but it never hurts to call to verify.
          </span>
        </Section>
        <Section
          title="The credit card I’m using offers rental car protection. Can I decline the insurance you provide?"
          isOpen={openSectionIndex === 4}
          onToggle={() => handleToggle(4)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            See response above and apply that to credit card companies. It would
            be wise to check first.
          </span>
        </Section>
        <Section
          title="Do you have a late policy?"
          isOpen={openSectionIndex === 5}
          onToggle={() => handleToggle(5)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            Yes, renters are given a 1 hour grace period with communication to
            the company. After 1 hour, renters will be charged at the per-day
            rental rate, plus a $25 late fee. After the car is returned, the
            renter will be refunded a prorated amount.
          </span>
        </Section>
        <Section
          title="Can I extend the rental?"
          isOpen={openSectionIndex === 6}
          onToggle={() => handleToggle(6)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            That depends on if the car has been reserved by another renter. If
            you are still in need of a vehicle, you can check our available
            inventory.
          </span>
        </Section>
        <Section
          title="What is your cancellation policy?"
          isOpen={openSectionIndex === 7}
          onToggle={() => handleToggle(7)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            You can modify or cancel up to 24 hours prior to the start of your
            rental period. Any modification or cancellation within 24 hours will
            incur a fee equal to one day’s rental.
          </span>
        </Section>
        <Section
          title="Are pets allowed in the vehicle?"
          isOpen={openSectionIndex === 8}
          onToggle={() => handleToggle(8)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            No pets are allowed in the vehicle.
          </span>
        </Section>
        <Section
          title="Is smoking allowed in the vehicle?"
          isOpen={openSectionIndex === 9}
          onToggle={() => handleToggle(9)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            Smoking is not allowed. Additionally, if the rental car is returned
            smelling like smoke of any kind, the renter will incur a fee of $250
            for deep cleaning.
          </span>
        </Section>
        <Section
          title="If there are any issues, what should I do?"
          isOpen={openSectionIndex === 10}
          onToggle={() => handleToggle(10)}
        >
          <span className="text-base font-normal leading-6 text-left text-[#797979]">
            Please contact our help desk at any time during your rental should
            you experience any issues.
          </span>
        </Section>
      </div>
    </>
  );
};

export default RentalPolicies;
